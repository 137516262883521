import React, { Component } from "react"
import Footer from "../components/Footer"
import styled from "styled-components"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import CrossfadeImage from "react-crossfade-image"
import CountUp from "react-countup"
import VisibilitySensor from 'react-visibility-sensor'
import Input from "../components/Input"
import ReactMapboxGl, { Marker, Popup } from 'react-mapbox-gl'
import mapboxgl from 'mapbox-gl'
import Navigation from "../components/Navigation"
import 'mapbox-gl/dist/mapbox-gl.css'
import ApolloClient from "apollo-client";
import {createHttpLink} from "apollo-link-http";
import {InMemoryCache} from "apollo-cache-inmemory";
import {gql} from "apollo-boost";
import MoonLoader from "react-spinners/MoonLoader"
import moment from "moment"
import "moment/locale/de"
import { ToastContainer, toast } from "react-toastify"
import emailjs from 'emailjs-com'
import "react-toastify/dist/ReactToastify.min.css"
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const ContactRef = React.createRef()

const Header = styled.div`
    width: 100%;
    height: 768px;
    background-size: cover;
    background-position: 50% 30%;
    position: relative;
  
    @media screen and (max-width: 800px) {
      height: 600px;
    }
  
    .background {
        position: absolute;
        top: 0;
        z-index: -1;
        height: 100%;
        width: 100%;
        overflow: hidden;
      
        img {
            width: 100%;
            object-fit: cover;
            height: 768px;

          @media screen and (max-width: 800px) {
            height: 600px;
          }
        }
    }
  
    .innerCarousel {
        margin-top: 80px;
        width: 100%;
        height: 512px;

      @media screen and (max-width: 800px) {
          margin-top: 0;
        
          .inner {
              padding: 24px !important;
          }
        
          h1 {
              font-size: 32px !important;
          }
      }
      
        .carouselItem {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 512px;
            color: white;
            text-align: left;
            cursor: grab;
            user-select: none;
          
            .inner {
                background: rgba(9, 16, 34, 0.9);
                padding: 48px;
                margin: 16px;
            }
          
            h1 {
                font-size: 40px;
                max-width: 650px;
                font-weight: 400;
                text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.5);
            }
          
            .dateContainer {
                width: 100%;
            }
          
            .date {
                display: flex;
                align-items: center;
                font-size: 14px;
                margin-bottom: 8px;
              
                &::before {
                    width: 32px;
                    height: 2px;
                    display: block;
                    content: " ";
                    background: #0069B4;
                    margin-right: 8px;
                }
            }
          
            .description {
                max-width: 650px;
                line-height: 32px;
                font-size: 20px;
                opacity: 0.75;
                margin-top: 12px;
            }
        }
    }
`

const ModuleSkills = styled.div`
    padding-bottom: 128px;
    padding-top: 96px;
  
    h2 {
        font-size: 30px;
        ::after {
            display: block;
            content: " ";
            width: 64px;
            height: 2px;
            margin-top: 8px;
            margin-bottom: 24px;
            background: #0069B4;
        }
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 48px;
      
        @media screen and (max-width: 1280px) {
            grid-template-columns: 1fr 1fr;
        }

        @media screen and (max-width: 768px) {
            grid-template-columns: 1fr;
        }
        
        .item {
            border-radius: 5px;
            height: 250px;
            box-sizing: border-box;
            padding: 24px;
            background-position: center;
            background-size: cover;
            color: white;
            user-select: none;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
          
            h3 {
                font-weight: 400;
                font-size: 32px;
            }
            
            h4 {
                opacity: 0.75;
                margin-top: 8px;
                font-size: 18px;
                line-height: 30px;
            }
        }
    }
`


const ModuleCustomers = styled.div`
    padding-bottom: 128px;
  
    h2 {
        font-size: 30px;
        ::after {
            display: block;
            content: " ";
            width: 64px;
            height: 2px;
            margin-top: 8px;
            margin-bottom: 24px;
            background: #0069B4;
        }
    }
    
    .testimonials {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 32px;
      
        .testimonial {
            position: relative;
            background: white;
            box-sizing: border-box;
            padding: 32px;
            box-shadow: 0 2px 8px -3px rgb(0 0 0 / 40%);
            border-radius: 4px;
          
            .quotations {
                position: absolute;
                font-size: 50px;
                z-index: 0;
                color: #0069B4;
                user-select: none;
                top: 32px;
                opacity: 0.25;
                right: 32px;
                left: auto;
            }
          
          .content {
            display: grid;
            grid-template-columns: auto auto;
            grid-gap: 32px;
            z-index: 2;
            position: relative;
            padding-left: 0;
            padding-right: 80px;
            
            @media screen and (max-width: 800px) {
                grid-template-columns: 100%;
            }
          }
          
            &.even {
                .content {
                  padding-left: 80px;
                  padding-right: 0;
                }
                .quotations {
                  right: auto;
                  left: 32px;
                }
            }
          
          
            p.text {
                font-size: 22px;
                line-height: 32px;
                font-style: italic;
            }
          
            p.customer {
                margin-top: 24px;
              
                b {
                    font-weight: 400;
                }
            }
            
            img {
                height: 100%;
                max-width: 200px;
                max-height: 80px;
                object-fit: contain;
            }
          
            &.even {
            
            }
        }
    }
`


const ModuleTitles = styled.div`
    width: 100%;
    padding: 80px 0;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
    text-align: center;
    background: white;
  
    h1 {
        font-weight: 400;
        font-size: 34px;
        color: #384c81;
    }
  
    h2 {
        margin-top: 16px;
        font-size: 28px;
        opacity: 0.75;
    }
`

const ModuleServices = styled.div`
padding-bottom: 96px;
    padding-top: 96px;
h2 {
        font-size: 30px;
        ::after {
            display: block;
            content: " ";
            width: 64px;
            height: 2px;
            margin-top: 8px;
            margin-bottom: 24px;
            background: #0069B4;
        }
    }
    
    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 48px;
      
        @media screen and (max-width: 1024px) {
            grid-template-columns: 100%;
        }
        
        .item {
            i {
                font-size: 30px;
                color:#091022;
                margin-bottom: 16px;
                width: 54px;
                height: 54px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                border: 3px solid #091022;
            }
            
            h3 {
                font-size: 24px;
                font-weight: 400;
            }
            
            p {
                margin-top: 8px;
                line-height: 32px;
                opacity: 0.75;
                font-size: 18px;
            }
        }
    }
`

/*const ModuleAbout = styled.div`
    padding-top: 96px;
    padding-bottom: 112px;
    background: white;

    @media screen and (max-width: 768px) {
        .content {
            grid-template-columns: 100% !important;
        }
    }

    h2 {
        font-size: 30px;
        ::after {
            display: block;
            content: " ";
            width: 64px;
            height: 2px;
            margin-top: 8px;
            margin-bottom: 24px;
            background: #0069B4;
        }
    }

    .content {
        display: grid;
        grid-template-columns: 2fr 1.25fr;
        grid-gap: 96px;

        .left {
            .title {
                text-transform: uppercase;
                margin-bottom: 16px;
                font-size: 18px;
                line-height: 26px;
            }

            .description {
                line-height: 34px;
                opacity: 0.75;
            }

            .signature {
                margin-top: 32px;
                opacity: 0.5;
                width: 280px;
                user-select: none;
            }
        }

        .right {
            perspective: 3000px;

            img {
                width: 100%;
                max-height: 560px;
                object-fit: cover;
                border-radius: 8px;
                box-shadow: 12px 12px 16px -4px rgb(0 0 0 / 15%);
                transform: rotate3d(0, 1, 0, -20deg);
            }
        }
    }
`*/

const ModuleFacts = styled.div`
    width: 100%;
    background: #384c81;
    color: white;
    padding: 64px 0;
    
    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 48px;
        
        @media screen and (max-width: 1024px) {
            grid-template-columns: 1fr 1fr;
        }

      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
          grid-gap: 64px;
      }
      
        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            
            .number {
                font-size: 48px;
            }
            
            .description {
                margin-top: 8px;
                opacity: 0.75;
                font-size: 20px;
            }
        }
    }
`

const ModuleContact = styled.div`
    padding: 96px 0;
    position: relative;
    
    .map {
        position: absolute;
        height: 100%;
        width: 100%;
        overflow: hidden;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        
        .markerIcon {
            background: white;
            width: 64px;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.5);
            
            img {
                height: 40px;
                width: 40px;
                object-fit: contain;
            }
        }
    }
    
    h2 {
        font-size: 30px;
        ::after {
            display: block;
            content: " ";
            width: 64px;
            height: 2px;
            margin-top: 8px;
            margin-bottom: 24px;
            background: #0069B4;
        }
    }
    
    .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 128px;
      
        @media screen and (max-width: 1024px) {
            grid-template-columns: 1fr;
          
            .left {
                margin-top: 512px;
            }
          
            .right {
                display: none;
            }
        }
        
        .left {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 24px;
            box-shadow: 0 4px 16px -4px rgba(0, 0, 0, 0.25);
            padding: 24px;
            background: white;
            border-radius: 5px;
            
            .button {
                background: #384c81;
                color: white;
                padding: 16px 20px;
                border-radius: 6px;
                box-shadow: 0 2px 8px -3px rgb(0 0 0 / 40%);
                cursor: pointer;
                transition: opacity 0.3s;
                text-align: center;
              
                &:hover {
                    opacity: 0.75;
                }
                
                i {
                    margin-left: 8px;
                }
            }
            
            .privacy {
                line-height: 24px;
                
                a {
                    color: #384c81;
                    text-decoration: none;
                    font-weight: 400;
                    
                    &:hover {
                        opacity: 0.5;
                    }
                }
            }
        }
        
        .right {
            
        }
    }
`

const Map = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoicmhlaW4tcnVoci1yZWFsIiwiYSI6ImNrdjVrOHFhMjFsOHgydW92Nnp5ejd0d3cifQ.AXZZo7iTSiagEtzGUOjZNQ'
});

const ModuleWelcome = styled.div`
    width: 100%;
    padding-top: 160px;
    padding-bottom: 128px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  
    .top {
        display: inline-block;
    }
  
    img {
        margin-bottom: 32px;
        width: 100%;
        max-height: 280px;
    }
  
    h1 {
        font-size: 34px;
    }
  
    h2 {
        margin-top: 16px;
        line-height: 40px;
        opacity: 0.75;
        display: inline-block;  
        width: 75%;
    }
`

const ModuleCallToAction = styled.div`
  padding: 54px 96px;
  border-radius: 8px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    
    .left {
      max-width: 100% !important;
    }
    
    .button {
        margin-top: 32px;
    }
  }
  
  .left {
    max-width: 65%;
    display: flex;

    i {
      color: #0069B4;
      margin-right: 20px;
      font-size: 50px;
    }

    .title {
      font-size: 22px;
      margin-bottom: 8px;
    }

    .description {
      opacity: 0.75;
      line-height: 28px;
    }
  }

  .right {
    .button {
      background: #0069B4;
      color: white;
      padding: 16px 20px;
      border-radius: 6px;
      box-shadow: 0 2px 8px -3px rgb(0 0 0 / 40%);
      cursor: pointer;
      transition: opacity 0.3s;
      white-space: nowrap;

      &:hover {
        opacity: 0.75;
      }

      i {
        margin-left: 8px;
      }
    }
  }
`

const LoadingContainer = styled.div`
    background: rgb(9, 16, 34);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    opacity: ${props => props.hide ? 0 : 1};
    transition: opacity 1s, visibility 1s;
    overflow-y: scroll;
    pointer-events: ${props => props.hide ? "none" : "unset"};
    visibility: ${props => props.hide ? "hidden" : "visible"};
`

class ContactForm extends Component {
    state = {
        firstName: null,
        lastName: null,
        company: null,
        email: null,
        phone: null,
        message: null,
        loading: false
    }

    render() {
        return <>
            <div style={{ gridColumnStart: 1, gridColumnEnd: 2 }}>
                <Input label="Vorname" icon="user" required value={this.state.firstName} onChange={firstName => this.setState({ firstName })} />
            </div>
            <div style={{ gridColumnStart: 2, gridColumnEnd: 3 }}>
                <Input label="Nachname" icon="user" required value={this.state.lastName} onChange={lastName => this.setState({ lastName })} />
            </div>
            <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                <Input label="Firma" icon="building" value={this.state.company} onChange={company => this.setState({ company })} />
            </div>
            <div style={{ gridColumnStart: 1, gridColumnEnd: 2 }}>
                <Input label="E-Mail Adresse" icon="envelope" required value={this.state.email} onChange={email => this.setState({ email })} />
            </div>
            <div style={{ gridColumnStart: 2, gridColumnEnd: 3 }}>
                <Input label="Telefonnummer" icon="phone" value={this.state.phone} onChange={phone => this.setState({ phone })} />
            </div>
            <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                <Input label="Ihre Nachricht an uns" icon="font" textArea required value={this.state.message} onChange={message => this.setState({ message })} />
            </div>
            <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }} className="privacy">
                Mit dem Klick auf Absenden bestätigen Sie, dass Sie die <a href="/#/legal#privacy">Datenschutzbestimmungen</a> gelesen und akzeptiert haben.
            </div>
            <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }} className="privacy">
                <div className="button" onClick={() => {
                    if(!this.state.loading) {
                        if(!this.state.firstName || !this.state.lastName || !this.state.email || !this.state.message) {
                            toast.error('Ihre Eingaben sind unvollständig. Bitte überprüfen Sie diese und versuchen Sie es erneut.', {
                                position: "bottom-left",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            })
                        } else {
                            this.setState({ loading: true })

                            emailjs.send("service_0zmiqgn", "template_z6ip2zd", {
                                first_name: this.state.firstName,
                                last_name: this.state.lastName,
                                company: this.state.company,
                                from_email: this.state.email,
                                phone: this.state.phone,
                                message: this.state.message
                            }, "user_Nd7MgxJ0YtNUEWFW7nrnt").then(data => {
                                if(data.status === 200) {
                                    this.setState({
                                        firstName: null,
                                        lastName: null,
                                        company: null,
                                        email: null,
                                        phone: null,
                                        message: null,
                                        loading: false
                                    })

                                    toast.success('Ihre Nachricht wurde erfolgreich versandt! Wir werden uns schnellstmöglich mit Ihnen in Verbindung setzen.', {
                                        position: "bottom-left",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    })
                                } else {
                                    toast.error('Beim Versenden Ihrer Kontaktfrage ist ein Fehler aufgetreten. Überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.', {
                                        position: "bottom-left",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    })

                                    this.setState({ loading: false })
                                }
                            }).catch(() => {
                                this.setState({ loading: false })
                                toast.error('Beim Versenden Ihrer Kontaktfrage ist ein Fehler aufgetreten. Überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.', {
                                    position: "bottom-left",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                })
                            })
                        }
                    }
                }}>Kontaktanfrage absenden<i className="far fa-paper-plane" /></div>
            </div>
        </>
    }
}

export default class PageStart extends Component {
    state = { headerIndex: 0, headers: [], testimonials: [] }

    constructor(props) {
        super(props)

        new ApolloClient({
            link: createHttpLink({
                uri: "https://api-eu-central-1.graphcms.com/v2/ckty66jlu224301z84ueo7m7k/master",
                fetch
            }),
            cache: new InMemoryCache({addTypename: false}),
            cachePolicy: {query: false, data: false},
            defaultOptions: {
                watchQuery: {
                    errorPolicy: 'all',
                    fetchPolicy: 'network-only'
                },
                query: {
                    errorPolicy: 'all',
                    fetchPolicy: 'network-only'
                },
                mutate: {
                    errorPolicy: 'all',
                    fetchPolicy: 'no-cache'
                }
            }
        }).query({
            query: gql`
                {
                    headers {
                        id
                        title
                        subtitle
                        date
                        backgroundImage {
                            url(transformation: { image: { resize: { width: 1920 } } })
                        }
                        backgroundVideo {
                            url
                        }
                    }
                    testimonials {
                        id
                        text
                        company
                        customer
                        logo {
                            url
                        }
                    }
                }
            `
        }).then(data => {
            const headers = data.data.headers

            headers.forEach(header => {
                const img = new Image()
                img.src = header.image
            })

            this.setState({ headers, testimonials: data.data.testimonials })
        })
    }

    render() {
        return <>
            <ToastContainer />
            <LoadingContainer hide={this.state.headers.length !== 0}>
                <MoonLoader color="#ffffff" size={50} />
            </LoadingContainer>
            {this.state.headers.length > 0 && <>
                <Header>
                    <Navigation contactRef={ContactRef} />
                    <div className="container">
                        <div className="innerCarousel">
                            <Carousel transitionTime={1500} onChange={headerIndex => {
                                this.setState({ headerIndex })
                            }} infiniteLoop centerMode={false} showArrows={false} emulateTouch showStatus={false} interval={6000} autoPlay>
                                {this.state.headers.map((header, index) => <div key={index} className="carouselItem">
                                    <div className="inner">
                                        <div className="dateContainer"><p className="date">{moment(header.date, "YYYY-MM-DD").locale("de").format("MMMM YYYY")}</p></div>
                                        <h1>{header.title}</h1>
                                        <p className="description">{header.subtitle}</p>
                                    </div>
                                </div>)}
                            </Carousel>
                        </div>
                    </div>
                    <div className="background">
                        {this.state.headers[this.state.headerIndex].backgroundImage !== null &&<CrossfadeImage duration={1500} src={this.state.headers[this.state.headerIndex].backgroundImage.url} />}
                        {this.state.headers[this.state.headerIndex].backgroundVideo !== null && <video key={this.state.headers[this.state.headerIndex].backgroundVideo.url} style={{ width: "100%", height: "100%", objectFit: "cover" }} muted playsInline autoPlay loop id="video"><source src={this.state.headers[this.state.headerIndex].backgroundVideo.url} type="video/mp4" /></video>}
                    </div>
                </Header>
                <ModuleWelcome>
                    <div className="container">
                        <div className="top">
                            <img src="/logo_on_light.svg" />
                            <h1>Transaktionsberatung. Exklusiv & off market.</h1>
                        </div>
                        <h2>Vom Erstkontakt bis hin zur erfolgreichen Abwicklung - wir stehen Ihnen während des gesamten Verkaufsprozesses tatkräftig zur Seite. Zählen Sie auf uns.</h2>
                    </div>
                </ModuleWelcome>
                <div style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)", borderTop: "1px solid rgba(0, 0, 0, 0.1)" }}>
                    <div className="container">
                        <ModuleCallToAction>
                            <div className="left">
                                <i className="far fa-home" />
                                <div>
                                    <p className="title">Verkaufsgedanke?</p>
                                    <p className="description">Wir bieten Ihnen mit einer kostenfreien Immobilienbewertung eine Entscheidungsgrundlage für die Zukunft. Anhand essenzieller Eckdaten Ihres Objekts sowie eines persönlichen Gesprächs ermitteln wir für Sie innerhalb von 3 Tagen kostenlos und unverbindlich den aktuellen Marktwert ihrer Immobilie.</p>
                                </div>
                            </div>
                            <div className="right">
                                <div className="button" onClick={() => ContactRef.current.scrollIntoView()}>Jetzt Kontakt aufnehmen<i className="far fa-arrow-right" /></div>
                            </div>
                        </ModuleCallToAction>
                    </div>
                </div>
                {/*<ModuleAbout>
            <div className="container">
                <h2>Geschäftsführung</h2>
                <div className="content">
                    <div className="left">
                        <p className="title">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.</p>
                        <p className="description">
                            Geschäftsführender Gesellschafter Benjamin Becker hat in den letzten 12 Jahren Immobilientransaktionen in jeglicher Form abgewickelt. Der ausgebildete Immobilienkaufmann war zunächst bei einem skandinavischen Immobilienunternehmen mit Sitz in Düsseldorf tätig, bevor er sich einem führenden Beratungsunternehmen im Bereich Investment-Vermittlung in NRW anschloss. Hier hat er im wesentlichen Single-Assets sowie Portfoliotransaktionen im wohn- und gewerblichen Bereich erfolgreich begleitet. 2014 hat er sich in die Selbstständigkeit begeben – seitdem betreut er erfolgreich das Vermittlungsgeschäft mit privaten als auch professionellen und institutionellen Investoren. Als Dienstleister im Transaktionssegment sind wir immer an sinnvollen Synergien interessiert. Der Immobilienmarkt ist mittlerweile so vielfältig geworden, dass man jeden noch so kleinen Ansatz genauer verfolgen sollte. Ein hoher Qualitätsstandard ist dabei unser Anspruch.
                        </p>
                        <img src="/signature.png" className="signature" />
                    </div>
                    <div className="right">
                        <img src="/benjamin_becker.jpg" />
                    </div>
                </div>
            </div>
        </ModuleAbout>*/}
                <ModuleSkills>
                    <div className="container">
                        <h2>Kompetenzbereich</h2>
                        <div className="grid">
                            <div style={{ backgroundImage: "linear-gradient(0deg, rgb(7 27 74 / 75%), rgb(7 27 74 / 75%)), url(/skills/mfh.jpg)" }} className="item">
                                <h3>Mehrfamilienhäuser</h3>
                            </div>
                            <div style={{ backgroundImage: "linear-gradient(0deg, rgb(7 27 74 / 75%), rgb(7 27 74 / 75%)), url(/skills/wal.jpg)" }} className="item">
                                <h3>Wohnanlagen</h3>
                            </div>
                            <div style={{ backgroundImage: "linear-gradient(0deg, rgb(7 27 74 / 75%), rgb(7 27 74 / 75%)), url(/skills/buero.jpg)" }} className="item">
                                <h3>Büro</h3>
                            </div>
                            <div style={{ backgroundImage: "linear-gradient(0deg, rgb(7 27 74 / 75%), rgb(7 27 74 / 75%)), url(/skills/fachmaerkte.jpg)" }} className="item">
                                <h3>Fachmärkte</h3>
                            </div>
                            <div style={{ backgroundImage: "linear-gradient(0deg, rgb(7 27 74 / 75%), rgb(7 27 74 / 75%)), url(/skills/logistik.jpg)" }} className="item">
                                <h3>Logistik</h3>
                            </div>
                            <div style={{ backgroundImage: "linear-gradient(0deg, rgb(7 27 74 / 75%), rgb(7 27 74 / 75%)), url(/skills/portfolio.jpg)" }} className="item">
                                <h3>Portfoliobereinigung</h3>
                            </div>
                        </div>
                    </div>
                </ModuleSkills>
                <ModuleTitles>
                    <div className="container">
                        <h1>Wir sind Ihr professioneller Partner für den Verkauf.</h1>
                        <h2>Verbindlich. Zuverlässig. Zielstrebig.</h2>
                    </div>
                </ModuleTitles>
                <ModuleServices>
                    <div className="container">
                        <h2>Dienstleistungen</h2>
                        <div className="grid">
                            <div className="item">
                                <i className="far fa-analytics" />
                                <h3>Immobilienbewertung</h3>
                                <p>Unter Berücksichtigung essenzieller Eckdaten ermitteln wir für Sie kostenfrei und unverbindlich den Wert Ihrer Immobilie. Für ein zuverlässiges Ergebnis empfehlen wir einen diskreten Ortstermin. Innerhalb von 3 Werktagen erhalten Sie Ihre Marktwertermittlung in digitaler Form.</p>
                            </div>
                            <div className="item">
                                <i className="far fa-handshake" />
                                <h3>Transaktionsberatung</h3>
                                <p>Unser Anspruch ist ein hoher Qualitätsstandard für unsere Kunden. Während des gesamten Vermarktungsprozesses, stehen wir Ihnen mit unserer langjährigen Expertise beratend zur Seite. Hierbei greifen wir auf unser vielfältiges Investorennetzwerk zurück.</p>
                            </div>
                            <div className="item">
                                <i className="far fa-home-alt" />
                                <h3>Exklusive Vermarktung</h3>
                                <p>Die Vermarktung jeglicher Liegenschaften erfolgt diskret und off market. Im Vorfeld erstellen wir ein aussagekräftiges Exposé, um eine möglichst transparente Informationsgrundlage für potenzielle Käufer zu schaffen. Die weiteren Abwicklungsschritte erläutern wir Ihnen gerne in einem persönlichen Gespräch.</p>
                            </div>
                        </div>
                    </div>
                </ModuleServices>
                <ModuleCustomers>
                    <div className="container">
                        <h2>Kundenmeinungen</h2>
                        <div className="testimonials">
                            {this.state.testimonials.map((testimonial, index) => <div className={`testimonial ${index % 2 === 0 ? "even" : ""}`} key={index}>
                                <i className="fas fa-quote-left quotations" />
                                <div className="content">
                                    {index % 2 !== 0 && <div className="logo">
                                        <img src={testimonial.logo ? testimonial.logo.url : null} />
                                    </div>}
                                    <div className="other">
                                        <p className="text">
                                            {testimonial.text}
                                        </p>
                                        <p className="customer">
                                            {testimonial.customer} - <b>{testimonial.company}</b>
                                        </p>
                                    </div>
                                    {index % 2 === 0 && <div className="logo">
                                        <img src={testimonial.logo ? testimonial.logo.url : null} />
                                    </div>}
                                </div>
                            </div>)}
                        </div>
                    </div>
                </ModuleCustomers>
                <ModuleFacts>
                    <div className="container">
                        <div className="grid">
                            <Counter description="Immobilientransaktionen" value={140} />
                            <Counter description="Vermittelte Einheiten" value={400} />
                            <Counter description="Jahre Erfahrung" value={12} />
                            <Counter description="Kundenzufriedenheit" value={100} suffix=" %" />
                        </div>
                    </div>
                </ModuleFacts>
                <ModuleContact>
                    <div className="map">
                        <DynamicMap />
                    </div>
                    <div className="container" id="contact">
                        <div className="content" ref={ContactRef}>
                            <div className="left">
                                <h2>Kontaktformular</h2>
                                <ContactForm />
                            </div>
                            <div className="right">

                            </div>
                        </div>
                    </div>
                </ModuleContact>
                <Footer />
            </>}
        </>
    }
}

const PopupContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    font-size: 16px;
    line-height: 26px;
`

class DynamicMap extends Component {
    state = {
        center: [ 6.774270, 51.425440 ]
    }

    constructor(props) {
        super(props)

        this.updateDimensions = this.updateDimensions.bind(this)

        this.updateDimensions(true)
    }

    updateDimensions(isConstructor) {
        let center = [ 6.774270, 51.445440 ]

        if(window.innerWidth < 1500) center = [ 6.764270, 51.445440 ]
        if(window.innerWidth < 1280) center = [ 6.778270, 51.445440 ]
        if(window.innerWidth < 1100) center = [ 6.788270, 51.445440 ]
        if(window.innerWidth <= 1024) center = [ 6.874270, 51.365440 ]

        if(isConstructor === true) this.state.center = center
        else this.setState({ center })
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
    }

    render() {
        return <Map
            style="mapbox://styles/rhein-ruhr-real/ckv5kkjtg6qn314o3zm5n7uz5"
            center={this.state.center}
            zoom={[11]}
            containerStyle={{
                height: '100%',
                width: '100%'
            }}>
            <Popup offset={[ 0, -50 ]} coordinates={[ 6.874270, 51.405440 ]} anchor="bottom">
                <PopupContent>
                    <img style={{ height: "130px" }} src="/logo_on_light.svg" />
                    <p style={{ fontWeight: "bold", marginTop: 24 }}>RHEIN-RUHR LIEGENSCHAFTEN GmbH</p>
                    <p>Düsseldorfer Straße 91-93</p>
                    <p>45481 Mülheim an der Ruhr</p>
                    <p style={{ marginTop: 16 }}><i className="far fa-envelope" /> kontakt@rheinruhr-liegenschaften.de</p>
                    <p><i className="far fa-phone" /> 0208 30 99 72 45</p>
                </PopupContent>
            </Popup>
            <Marker coordinates={[ 6.874270, 51.405440 ]} anchor="center">
                <div className="markerIcon">
                    <img src="/favicon.ico" />
                </div>
            </Marker>
        </Map>
    }
}

class Counter extends Component {
    state = { animated: false }

    render() {
        return <div className="item">
            <div className="number">
                <CountUp
                    start={0}
                    end={this.props.value}
                    duration={3}
                    decimals={0}
                    suffix={this.props.suffix || "+"}
                >
                    {({ countUpRef, start }) => (
                        <VisibilitySensor partialVisibility onChange={isVisible => {
                            if(isVisible && !this.state.animated) {
                                this.setState({ animated: true })
                                start()
                            }
                        }} delayedCall>
                            <span ref={countUpRef} />
                        </VisibilitySensor>
                    )}
                </CountUp>
            </div>
            <div className="description">{this.props.description}</div>
        </div>
    }
}
