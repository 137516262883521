import React, { Component } from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import { HashLink } from 'react-router-hash-link'

const StyledNavigation = styled.div`
  position: relative;
  a {
    text-decoration: none;
  }
  .navigation {
      width: 100%;
      display: flex;
      align-items: center;
      height: 96px;
      justify-content: space-between;
    
      .items {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        @media screen and (max-width: 1024px) {
            display: none;
        }
        
    
        a.topItem, a.callToAction {
          color: white;
          user-select: none;
          cursor: pointer;
          line-height: 40px;
          transition: color 0.3s;
          white-space: nowrap;
          font-size: 16px;
          letter-spacing: 1.35px;
          position: relative;
          height: 96px;
          display: flex;
            align-items: center;
          
          .fa-chevron-up {
              display: none;
          }
          
          .fa-chevron-up, .fa-chevron-down {
              margin-left: 8px;
          }
          
          &:hover {
              .fa-chevron-up {
                  display: inline-block;
              }
            
              .fa-chevron-down {
                  display: none;
              }
          }
          
          .dropdown {
              position: absolute;
              background: #23386c;
              top: 96px;
              width: 300px;
              left: -24px;
              display: none;
              z-index: 10;
              
              .dropdownItem {
                  height: 64px;
                  display: flex;
                  align-items: center;
                  box-sizing: border-box;
                  padding: 0 24px;
                  color: white;
                
                  &:nth-child(even) {
                      background: rgba(0, 0, 0, 0.1);
                  }
                
                  &:hover {
                      background: rgba(0, 0, 0, 0.2);
                  }
              }
          }
    
          &::after {
            display: block;
            content: " ";
            height: 2px;
            background: #0069B4;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            transition: width 0.3s;
            width: 0;
            bottom: 32px;
          }
    
          &:hover::after {
            width: 100%;
          }
    
          &:hover {
            color: white;
            
            .dropdown {
                display: block;
            }
          }
    
          &.callToAction {
            background: #0069B4;
            color: white;
            padding: 0 16px;
            border-radius: 5px;
            box-shadow: 0 2px 8px -3px rgba(0, 0, 0, 0.4);
            height: 40px;
    
            i {
              margin-left: 4px;
            }
          }
        }
      }
    
      .logo {
        padding-right: 80px;
        -webkit-user-drag: none;
        user-select: none;
        flex-shrink: 0;
        cursor: pointer;
        height: 74px;

        @media screen and (max-width: 1024px) {
          padding-right: 0;
        }
      }
    }
  
  .hamburger {
      display: none;
      color: white;
      font-size: 32px;
      cursor: pointer;
      transition: opacity 0.3s;
    
      &:hover {
          opacity: 0.5;
      }
    
      @media screen and (max-width: 1024px) {
          display: inline-block;
      }
  }
  
  .mobileMenu {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #23386c;
      transform: translateY(100%);
      box-sizing: border-box;
      z-index: 10;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
      user-select: none;
      overflow: hidden;
      max-height: ${props => props.height};
      transition: max-height 0.5s;
    
      @media screen and (min-width: 1025px) {
          display: none;
      }
    
      ul > li {
          list-style-type: none;
          min-height: 64px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;
          color: rgba(255, 255, 255, 0.75);
          transition: color 0.3s, background 0.3s;
        
          .content {
              display: flex;
              width: 100%;
              justify-content: space-between;
              height: 64px;
              align-items: center;
            padding: 0 32px;
              box-sizing: border-box;
          }
        
          ol {
            background: rgba(0, 0, 0, 0.1);
            overflow: hidden;
            transition: max-height 0.5s;
            
            li {
              height: 48px;
              width: 100%;
              display: flex;
              align-items: center;
              padding: 0 48px;
              box-sizing: border-box;
              color: rgba(255, 255, 255, 0.75);
              transition: color 0.3s, background 0.3s;
              
                &:hover {
                  background: rgba(0, 0, 0, 0.1);
                  color: white;
                }
            }
          }
        
          &:nth-child(even) {
              background: rgba(0, 0, 0, 0.1);
          }

        &:hover {
          background: rgba(0, 0, 0, 0.2);
          color: white;
        }
      }
  }
`

const items = [
    {
        url: "/",
        title: "Startseite"
    },
    {
        title: "Unternehmen",
        items: [
            {
                url: "/company#top",
                title: "Über uns"
            },
            {
                url: "/company#ceo",
                title: "Geschäftsführung"
            }
        ]
    },
    {
        url: "/testimonials",
        title: "Deals"
    },
    {
        url: "/legal",
        title: "Rechtliches"
    }
]

export default withRouter(class extends Component {
    state = { mobileMenuOpen: false, dropdownOpen: null }

    render() {
        return <StyledNavigation height={this.state.mobileMenuOpen ? ((items.length * 64 + (items.map(it => it.items || []).flat().length * 48)) + "px") : 0}>
            <div style={{ background: "rgb(9, 16, 34)", width: "100%" }}>
                <div className="container">
                    <div className="navigation">
                        <img className="logo" src="/logo_on_dark.svg" onClick={() => this.props.history.push("/start")} />
                        <div className="items">
                            {items.map((item, index) => <a className="topItem" key={index} onClick={() => this.props.history.push(item.url)}>
                                {item.title}
                                {item.items && <i className="far fa-chevron-down" />}
                                {item.items && <i className="far fa-chevron-up" />}
                                {item.items && <div className="dropdown">
                                    <ul>
                                        {item.items.map((item, index) => <HashLink to={item.url}><div className="dropdownItem"  key={index}>{item.title}</div></HashLink>)}
                                    </ul>
                                </div>}
                            </a>)}
                            <a className="callToAction" onClick={() => this.props.contactRef ? this.props.contactRef.current.scrollIntoView() : this.props.history.push("/")}>Kontakt <i className="fas fa-phone" /></a>
                        </div>
                        <div className="hamburger" onClick={() => this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen, dropdownOpen: null })}>
                            <i className="fas fa-bars" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobileMenu">
                <ul>
                    {items.map((item, index) => <li key={index} onClick={() => {
                        if(item.items) {
                            if(this.state.dropdownOpen === index) this.setState({ dropdownOpen: null })
                            else this.setState({ dropdownOpen: index })
                        } else {
                            this.props.history.push(item.url)
                            this.setState({ mobileMenuOpen: false })
                        }
                    }}>
                        <div className="content">
                            {item.title}
                            {item.items && <i className={`far fa-${this.state.dropdownOpen === index ? "chevron-up" : "chevron-down"}`} />}
                        </div>
                        {item.items && <ol className={this.state.dropdownOpen === index ? "open" : ""} style={{ maxHeight: this.state.dropdownOpen === index ? (item.items.length * 48 + "px") : 0 }}>
                            {item.items.map((it, index) => <HashLink to={it.url} onClick={() => {
                                this.setState({ mobileMenuOpen: false })}
                            }><li key={index}>
                                {it.title}
                            </li></HashLink>)}
                        </ol>}
                    </li>)}
                </ul>
            </div>
        </StyledNavigation>
    }
})
