import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import {
    HashRouter,
    Switch,
    Route
} from "react-router-dom"

import PageStart from "./pages/PageStart"
import PageLegal from "./pages/PageLegal"
import PageTestimonials from "./pages/PageTestimonials"
import PageCompany from "./pages/PageCompany"

ReactDOM.render(
    <React.StrictMode>
        <HashRouter>
            <App>
                <Switch>
                    <Route path="/legal">
                        <PageLegal />
                    </Route>
                    <Route path="/testimonials">
                        <PageTestimonials />
                    </Route>
                    <Route path="/company">
                        <PageCompany />
                    </Route>
                    <Route path="/">
                        <PageStart />
                    </Route>
                </Switch>
            </App>
        </HashRouter>
    </React.StrictMode>,
    document.getElementById('root')
)
