import React, { Component } from "react"
import styled from "styled-components"
import {withRouter} from "react-router-dom"
import moment from "moment"
import { HashLink } from 'react-router-hash-link'

const StyledFooter = styled.footer`
    padding-top: 64px;
    background: #091022;
    color: white;
    position: relative;
    
    .up {
        position: absolute;
        top: -64px;
        right: 32px;
        width: 40px;
        height: 40px;
        background: #23386c;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: background 0.3s;
      
        &:hover {
            background: #0069B4;
        }
    }
  
    .container {
        position: relative;
    }
  
    .credit {
        width: 100%;
        height: 40px;
        background: #23386c;
        margin-top: 64px;
        user-select: none;       
        font-size: 14px; 
        
        .container {
            display: flex;
            justify-content: space-between;
            height: 100%;
            align-items: center;
        }
    }
    
    .content {
        display: grid;
        grid-template-columns: calc(20% - 36px) calc(35% - 26px) calc(20% - 36px) calc(25% - 36px);
        grid-gap: 48px;
      
        @media screen and (max-width: 1024px) {
            grid-template-columns: calc(50% - 24px) calc(50% - 24px);
        }
      
      @media screen and (max-width: 768px) {
          grid-template-columns: 100%;
          text-align: center;
        
          ul li {
              justify-content: center !important;
          }
        }


      .column {
            flex-shrink: 0;
          
            .title {
                font-size: 22px;
                margin-bottom: 16px;
                user-select: none;
            }

          @media screen and (min-width: 1025px) {
            &:last-child {
              text-align: right;

              ul li {
                justify-content: flex-end;

                a {
                  &::before {
                    display: none !important;
                  }

                  &:hover {
                    ::after {
                      width: 16px;
                      margin-left: 6px;
                    }
                  }
                }
              }
            }
          }
          
            
            
            ul {
                li {
                    list-style-type: none;
                    line-height: 36px;
                    cursor: pointer;
                    user-select: none;
                    display: flex;
                  white-space: nowrap;
                    
                    a {
                        color: inherit;
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                        opacity: 0.5;
                        transition: opacity 0.3s;
                        
                        
                        i {
                            width: 24px;
                        }
                        
                        ::before, ::after {
                            background: #0069B4;
                            content: " ";
                            display: block;
                            width: 0;
                            height: 2px;
                            transition: all 0.3s;
                        }
                        
                        &:hover {
                            opacity: 1;
                            
                            ::before {
                                width: 16px;
                                margin-right: 6px;
                            }
                        }
                    }
                }
            }
        }
    }
`

export default withRouter(class extends Component {
    state = { year: null }

    constructor(props) {
        super(props)

        this.state.year = moment().year()
    }

    render() {
        return <StyledFooter>
            <div className="container">
                <div className="up" onClick={() => {
                    window.scrollTo(0, 0)
                }}><i className="far fa-chevron-up" /></div>
                <div className="content">
                    <div className="column">
                        <p className="title">Quick Links</p>
                        <ul>
                            <li><a onClick={() => this.props.history.push("/")}>Startseite</a></li>
                            <li><a onClick={() => this.props.history.push("/company")}>Unternehmen</a></li>
                            <li><a onClick={() => this.props.history.push("/testimonials")}>Deals</a></li>
                        </ul>
                    </div>
                    <div className="column">
                        <p className="title">Kontakt</p>
                        <ul>
                            <li><a href="mailto:kontakt@rheinruhr-liegenschaften.de"><i className="far fa-envelope" />E-Mail: kontakt@rheinruhr-liegenschaften.de</a></li>
                            <li><a href="tel:+4920830997245"><i className="far fa-phone" />Telefon: 0208 30 99 72 45</a></li>
                        </ul>
                    </div>
                    <div className="column">
                        <p className="title">Social Media</p>
                        <ul>
                            <li><a onClick={() => window.open("https://www.linkedin.com/company/rhein-ruhr-liegenschaften-gmbh/", "_blank")}><i className="fab fa-linkedin" />LinkedIn</a></li>
                            <li><a onClick={() => window.open("https://www.instagram.com/rheinruhrliegenschaften/", "_blank")}><i className="fab fa-instagram" />Instagram</a></li>
                        </ul>
                    </div>
                    <div className="column">
                        <p className="title">Rechtliches</p>
                        <ul>
                            <li><HashLink to="/legal#top" onClick={() => this.props.history.push("/legal#top")}>Impressum</HashLink></li>
                            <li><HashLink to="/legal#privacy" onClick={() => this.props.history.push("/legal#privacy")}>Datenschutz</HashLink></li>
                            <li><HashLink to="/legal#disclaimer" onClick={() => this.props.history.push("/legal#disclaimer")}>Haftungsausschluss</HashLink></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="credit">
                <div className="container">
                    <div className="left">
                        Crafted with <i className="far fa-heart" /> in Germany
                    </div>
                    <div className="right">
                        Copyright <i className="far fa-copyright" /> 2022 RHEIN-RUHR LIEGENSCHAFTEN GmbH
                    </div>
                </div>
            </div>
        </StyledFooter>
    }
})
