import React, { Component } from "react"
import CookieBanner from "react-cookie-banner"

export default class extends Component {
    render() {
        return <>
            <CookieBanner
                message="Wir benutzen Cookies um Ihnen beim Aufrufen dieser Seite ein optimales Nutzungserlebnis zu ermöglichen. Durch das Surfen auf dieser stimmen Sie letzterem zu."
                link={<a href='http://nocookielaw.com/'>Mehr erfahren</a>}
                buttonMessage="Schließen"
                cookie="user-has-accepted-cookies"
                styles={{
                    banner: {
                        height: 64,
                        background: "rgba(0, 0, 0, 0.8)",
                        display: "flex",
                        alignItems: "center",
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        boxSizing: "border-box",
                        padding: "0 24px"
                    },
                    message: {
                        fontWeight: 300
                    },
                    button: {
                        background: "#3867d6",
                        color: "white",
                        height: 40,
                        marginTop: 0,
                        top: 12,
                        padding: "0 20px"
                    },
                    link: {
                        color: "rgba(255, 255, 255, 0.5)"
                    }
                }}
            />
            {this.props.children}
        </>
    }
}
