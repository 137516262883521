import React, { Component } from "react"
import styled from "styled-components"

const StyledContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    
    .label {
        width: 100%;
        margin-bottom: 8px;
        height: 16px;
        
        .required {
            color: #0069B4;
            font-size: 8px;
            vertical-align: top;
            margin-left: 4px;
        }
    }
  
    .icon {
        position: absolute;
        top: 40px;
        left: 16px;
    }
    
    input, textarea {
        outline: 0;
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        box-sizing: border-box;
        padding: 8px 16px;
        height: 48px;
        font-family: inherit;
        transition: border-color 0.3s;
        padding-left: 40px;
        
        &::placeholder {
            font-size: inherit !important;
            font-family: inherit !important;
        }
        
        &:focus {
            border-color: #0069B4;
        }
    }
    
    textarea {
        height: 150px;
        resize: none;
        padding-top: 14px;
    }
`

export default class extends Component {
    state = { value: null }

    render() {
        return <StyledContainer>
            {this.props.label && <div className="label">
                {this.props.label}{this.props.required && <i className="far fa-asterisk required" />}
            </div>}
            {this.props.textArea && <textarea autoComplete="false" autoCorrect="false" spellCheck="false" placeholder={this.props.label} value={this.props.value || this.state.value || ""} onChange={e => {
                if(this.props.onChange) this.props.onChange(e.target.value)
                else this.setState({ value: e.target.value })
            }} />}
            {!this.props.textArea && <input value={this.props.value || this.state.value || ""} autoComplete="false" autoCorrect="false" spellCheck="false" placeholder={this.props.label} onChange={e => {
                if(this.props.onChange) this.props.onChange(e.target.value)
                else this.setState({ value: e.target.value })
            }} />}
            {this.props.icon && <i className={`icon far fa-${this.props.icon}`} />}
        </StyledContainer>
    }
}
