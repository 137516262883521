import React, { Component } from "react"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
import SmallHeader from "../components/SmallHeader"
import styled from "styled-components"

const TextContainer = styled.div`
    width: 100%;
    padding: 96px 0;
    font-size: 18px;
    line-height: 32px;
  
    h1 {
        margin-bottom: 16px;
        margin-top: 32px;
        color: #384c81;
        font-weight: 400;
        font-size: 40px;
        line-height: 50px;
      
        &:first-of-type {
            margin-top: 0;
        }
    }
`

const ModuleTitles = styled.div`
    width: 100%;
    padding: 80px 0;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
    text-align: center;
    background: white;
  
    h1 {
        font-weight: 400;
        font-size: 30px;
        color: #384c81;
    }
  
    h2 {
        margin-top: 16px;
        font-size: 24px;
        opacity: 0.75;
    }
`

const StyledContact = styled.div`
    background: white;
    padding: 32px;
    display: inline-block;
    margin-top: 32px;
    border-radius: 5px;
    box-shadow: 0 2px 8px -3px rgb(0 0 0 / 40%);
  
    img {
        width: 250px;
        -webkit-user-drag: none;
        border-radius: 5px;
    }
  
    p:nth-child(2) {
        font-size: 24px;
        font-weight: 400;
        margin-top: 32px;
    }
  
    p:nth-child(3), p:nth-child(3) {
        opacity: 0.75;
    }
  
    p:nth-child(4) {
        margin-top: 16px;
    }
  
    p {
        i {
            width: 24px;
        }
    }
`

export default class extends Component {
    render() {
        return (
            <div>
                <Navigation />
                <SmallHeader title="Unternehmen" subtitle="Über uns & unsere Geschäftsführung" background="/headers/Header_2.jpg" />
                <TextContainer>
                    <div className="container" id="about">
                        <h1 style={{ marginBottom: 32 }}>Unternehmen</h1>
                        <p>Unser Unternehmen verfügt über langjährige Erfahrung in der Transaktionsberatung mit verschiedenen Kernbereichen. Unsere Kompetenzen erstrecken sich von der Vermittlung wohnwirtschaftlicher Liegenschaften in Form von Wohnanlagen, Portfolios, Mehrfamilienhäuser und Wohn- und Geschäftshäuser bis hin zu gewerblich genutzten Immobilien wie Büros, Fachmarktzentren sowie Light-Industrial. Dabei profitieren wir nicht nur von unserer hervorragenden Marktdurchdringung, sondern auch durch unser Netzwerk zu diversen Investorenkreisen. Hierzu gehören klassische Kapitalanleger, vermögende Privatinvestoren, mittelständische Immobilienunternehmen, Kapitalverwaltungsgesellschaften, Multi-/ sowie Single-Family-Offices sowie Projektentwickler. Unsere Ambition: ein umfassender und hoher Qualitätsstandard, verbunden mit einer reibungslosen Abwicklung vom Erstkontakt bis zum Verkauf. Hierfür garantieren wir.</p>
                    </div>
                </TextContainer>
                <ModuleTitles>
                    <div className="container">
                        <h1>Unsere Ambition ist ein hoher sowie vollumfänglicher Qualitätsstandard.</h1>
                        <h2>Transparent. Kompetent. Fair.</h2>
                    </div>
                </ModuleTitles>
                <TextContainer>
                    <div className="container" id="ceo">
                        <h1 style={{ marginBottom: 32 }}>Geschäftsführung</h1>
                        <p>
                            Benjamin Becker hat in den letzten 12 Jahren Immobilientransaktionen in jeglicher Form abgewickelt. Der ausgebildete Immobilienkaufmann war zunächst bei einem skandinavischen Immobilienunternehmen mit Sitz in Düsseldorf tätig, bevor er sich einem führenden Beratungsunternehmen im Bereich Investment-Vermittlung in NRW anschloss. Hier hat er im wesentlichen Single-Assets sowie Portfoliotransaktionen im wohn- und gewerblichen Bereich erfolgreich begleitet. 2014 hat er sich in die Selbstständigkeit begeben – seitdem betreut er erfolgreich das Vermittlungsgeschäft mit privaten als auch professionellen und institutionellen Investoren. Als eigenständiges und unabhängiges Immobilienunternehmen im Bereich wohnwirtschaftlicher- sowie gewerblicher Immobilieninvestments haben wir den Anspruch, uns den jeweiligen strategischen Ausrichtungen und Entscheidungen der Investoren sowie des Immobilienmarktes zu stellen. Das Ziel besteht darin, eine faire und vertrauensvolle Zusammenarbeit mit unseren Kunden in allen Bereichen zu generieren. Unsere Ambition: ein umfassender und hoher Qualitätsstandard, verbunden mit einer reibungslosen Abwicklung vom Erstkontakt bis zum Verkauf.
                        </p>
                        {/*<div style={{ position: "relative", marginTop: 32 }}>
                            <i className="fas fa-quote-left" style={{ position: "absolute", top: 0, left: 0, fontSize: "32px", color: "#0069B4", userSelect: "none" }} />
                            <p style={{ fontStyle: "italic", paddingLeft: 48 }}>
                                Als Dienstleister im Transaktionssegment sind wir immer an sinnvollen Synergien interessiert. Der Immobilienmarkt ist mittlerweile so vielfältig geworden, dass man jeden noch so kleinen Ansatz genauer verfolgen sollte. Ein hoher Qualitätsstandard ist dabei unser Anspruch. Als eigenständige und unabhängiges Immobilienunternehmen im Bereich wohnwirtschaftlicher- sowie gewerblicher Immobilieninvestments haben wir den Anspruch, uns den jeweiligen strategischen Ausrichtungen und Entscheidungen der Investoren sowie des Immobilienmarktes zu stellen. Das Ziel besteht darin, eine faire und vertrauensvolle Zusammenarbeit mit unseren Kunden in allen Bereichen zu generieren. Unsere Ambition: ein umfassender und hoher Qualitätsstandard, verbunden mit einer reibungslosen Abwicklung vom Erstkontakt bis zum Verkauf.
                            </p>
                        </div>*/}
                        <hr style={{ height: 1, width: "100%", border: "none", background: "rgba(0, 0, 0, 0.25)", margin: "64px 0" }} />
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <h1>Ihr persönlicher Ansprechpartner</h1>
                            <StyledContact>
                                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                    <img src="/benjamin_becker.jpg" />
                                </div>
                                <p>Benjamin Becker</p>
                                <p>Geschäftsführender Gesellschafter</p>
                                <p><i className="far fa-phone" /> 0208 30 99 72 45</p>
                                <p><i className="far fa-mobile" /> 0178 696 10 33</p>
                                <p><i className="far fa-envelope" /> b.becker@rheinruhr-liegenschaften.de</p>
                            </StyledContact>
                        </div>
                    </div>
                </TextContainer>
                <Footer />
            </div>
        )
    }
}
