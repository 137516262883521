import React, { Component } from "react"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
import SmallHeader from "../components/SmallHeader"
import styled from "styled-components"
import ApolloClient from "apollo-client"
import { InMemoryCache } from "apollo-cache-inmemory"
import { createHttpLink } from "apollo-link-http"
import { gql } from "apollo-boost"
import {Carousel} from "react-responsive-carousel";

const TextContainer = styled.div`
    width: 100%;
    padding: 96px 0;
    font-size: 18px;
    line-height: 32px;
  
    h2 {
        line-height: 44px;
    }
  
    h1 {
        margin-bottom: 16px;
        margin-top: 32px;
        color: #384c81;
        font-weight: 400;
        font-size: 40px;
      
        &:first-of-type {
            margin-top: 0;
        }
    }
`

const Testimonials = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 80px;
    margin-top: 64px;
  
    @media screen and (max-width: 1280px) {
        grid-template-columns: 1fr 1fr;
    }

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`

const StyledTestimonial = styled.div`
    width: 100%;
    height: 250px;
    position: relative;
  
    * {

      user-select: none;
      
    }
  
    .carouselContainer {
        z-index: 3;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        overflow: hidden;
        height: 250px;
      
        .carouselItem {
          height: 250px;
            img {
                width: 100%;
                height: 250px;
                object-fit: cover;
            }
        }
    }

  @media screen and (max-width: 1280px) {
      height: 350px;
    
      .carouselContainer, .carouselItem, img {
          height: 350px !important;
      }
  }

  @media screen and (max-width: 900px) {
    height: 400px;

    .carouselContainer, .carouselItem, img {
      height: 400px !important;
    }
  }
  
    .content {
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        background: rgb(9 16 34 / 72%);
        color: white;
        box-sizing: border-box;
        padding: 16px 32px;
        font-size: 18px;
        transition: opacity 0.3s;
        user-select: none;
        position: relative;
        z-index: 5;
        pointer-events: none;
        text-align: center;
        
        .watermark {
            position: absolute;
            top: 5%;
            right: 5%;
            height: 20%;
            opacity: 0.3;
            max-height: 128px;
        }
      
        p {
            position: relative;
            z-index: 5;
        }
      
        p:first-child {
            font-weight: 400;
            font-size: 20px;
        }
      
        p:last-child {
            opacity: 0.75;
        }
    }
  
    &:hover {
        .content {
            opacity: 1;
        }
    }
`

class Testimonial extends Component {
    render() {
        return <StyledTestimonial>
            <div className="carouselContainer">
                <Carousel transitionTime={500} infiniteLoop centerMode={false} showArrows={true} showThumbs={false} emulateTouch showStatus={false} interval={3000} autoPlay>
                    {this.props.backgrounds.map((background, index) => <div key={index} className="carouselItem">
                        <img src={background.url} />
                    </div>)}
                </Carousel>
            </div>
            <div className="content">
                <img src="/favicon_light.svg" className="watermark" />
                <p>{this.props.title}</p>
                <p>{this.props.subtitle}</p>
            </div>
        </StyledTestimonial>
    }
}

export default class PageTestimonials extends Component {
    state = { testimonials: [] }

    constructor(props) {
        super(props)

        new ApolloClient({
            link: createHttpLink({
                uri: "https://api-eu-central-1.graphcms.com/v2/ckty66jlu224301z84ueo7m7k/master",
                fetch
            }),
            cache: new InMemoryCache({addTypename: false}),
            cachePolicy: {query: false, data: false},
            defaultOptions: {
                watchQuery: {
                    errorPolicy: 'all',
                    fetchPolicy: 'network-only'
                },
                query: {
                    errorPolicy: 'all',
                    fetchPolicy: 'network-only'
                },
                mutate: {
                    errorPolicy: 'all',
                    fetchPolicy: 'no-cache'
                }
            }
        }).query({
            query: gql`
                {
                    deals {
                        id
                        title
                        subtitle
                        position
                        backgroundImages {
                            url(transformation: { image: { resize: { width: 550 } } })
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ testimonials: data.data.deals })
        })
    }

    render() {
        return (
            <div>
                <Navigation />
                <SmallHeader title="Deals" subtitle="Unsere treuen Kunden und vermittelten Objekte" background="/headers/Header.jpg" />
                <div>
                    <TextContainer>
                        <div className="container">
                            <h1>Deals</h1>
                            <h2>Unsere erfolgreichen Transaktionen an exklusiv vermittelten Wohnanlagen, Mehrfamilienhäusern sowie gewerblichen Liegenschaften.</h2>
                            <Testimonials>
                                {this.state.testimonials.sort((a, b) => b.position - a.position).map((testimonial, key) => <Testimonial key={key} backgrounds={testimonial.backgroundImages} title={testimonial.title} subtitle={testimonial.subtitle} />)}
                            </Testimonials>
                        </div>
                    </TextContainer>
                </div>
                <Footer />
            </div>
        )
    }
}
