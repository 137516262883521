import React, { Component } from "react"
import styled from "styled-components"

const StyledHeader = styled.div`
    height: 512px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: linear-gradient(45deg,rgb(21 40 62 / 64%),rgb(59 83 145 / 45%)),url(${props => props.background});
    background-size: cover;
    user-select: none;
    background-position: center;
  
    h1 {
        font-size: 48px;
        font-weight: 400;
      
        &::after {
            display: block;
            content: " ";
            width: 64px;
            height: 2px;
            background: rgb(9, 16, 34);
            margin: 16px 0;
        }
    }
  
    h2 {
        font-size: 30px;
    }
  
    h1, h2 {
        text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.25);
        color: white;
    }
`

export default class extends Component {
    render() {
        return <StyledHeader background={this.props.background || "https://unsplash.com/photos/_K-QKkbn7Ds/download?ixid=MnwxMjA3fDB8MXxzZWFyY2h8MTl8fGJ1aWxkaW5nfHwwfHx8fDE2MzgwNDM2NTE&force=true"}>
            {/*<div className="container">
                <h1>{this.props.title}</h1>
                <h2>{this.props.subtitle}</h2>
            </div>*/}
        </StyledHeader>
    }
}
